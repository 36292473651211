@import '../../assets/sass/helper';

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 42px;
    padding-bottom: 22px;
    z-index: 10;
    //pointer-events: none;
    transition-property: background-color, padding-top, padding-bottom, transform;
    transition-duration: 0.5s;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include media_991(min) {
        padding-top: 22px;
    }

    .main-logo {
        position: relative;
        z-index: 1;
    }

    .topBar-nav {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        z-index: -2;

        -webkit-animation: slide-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;
        animation: slide-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s both;

        // &:after {
        //     position: absolute;
        //     right: 0;
        //     top: 100%;
        //     content: '';
        //     height: 1px;
        //     width: 100%;
        //     background: linear-gradient(
        //         90deg,
        //         rgba(255, 255, 255, 0) 80%,
        //         rgba(255, 255, 255, 1) 100%
        //     );
        // }

        @include media_991(min) {
            justify-content: flex-end;
        }

        & p {
            user-select: none;

            span {
                user-select: text;
            }
        }

        @-webkit-keyframes slide-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes slide-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .topBar-social {
        z-index: 1;
    }

    ul.primary-nav {
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        flex-wrap: wrap;

        ul {
            position: absolute;
        }
    }

    @include media_991(min) {
        &:not(.dsn-hamburger) {
            @import 'menu/classic';

            .menu-cover-title {
                display: none;
            }
        }
    }

    &.dsn-hamburger {
        @import 'menu/hamburger';
    }

    &.nav-bg {
        background-color: $bg-color;
    }
    &.hide-nav:not(.dsn-open) {
        transform: translateY(-100%);
    }
}
