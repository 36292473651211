input,
textarea,
.form-control,
.form-box input,
.form-box textarea {
    background-color: transparent;
    border: none;
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 1.7;
    padding: 1rem;

    &,
    &::placeholder {
        color: $body-color;
        font-size: 14px;
    }

    @include media_768 {
        flex: unset;
        display: block;
        width: 100%;
    }

    &:focus {
        background: $assistant-color;
        border: none;
        box-shadow: none;
    }
}

textarea,
.form-control,
.form-box textarea {
    min-height: 120px;
}

input[type='search'] {
    &::-webkit-search-decoration {
        display: none;
    }
}

.form-box {
    position: relative;

    .form-group {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
        margin-bottom: 30px;
        @include media_768 {
            flex-direction: column;
            label {
                flex: unset;
                display: block;
                margin-bottom: 10px;
            }
        }

        label {
            flex: 0 0 auto;
            padding-right: 30px;
            color: $heading-color;
            font-size: 15px;
            letter-spacing: 1px;
        }
    }
}
