@import '../../assets/sass/helper';

.footer {
    position: relative;

    @include media_575(max) {
        .footer-links {
            text-align: center;
        }
    }

    .dsn-col-footer {
        .footer-title {
            position: relative;
            color: $heading-color;
            text-transform: uppercase;
            font-size: 15px;
            padding-bottom: 10px;
            margin-bottom: 25px;
            letter-spacing: 2px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 15px;
                height: 2px;
                background-color: $heading-color;

                @include media_575(max) {
                    left: 50%;
                    transform: translate(-50%);
                }
            }
        }

        ul.col-menu li {
            font-size: 14px;
            font-weight: 600;
            overflow: hidden;
            &:not(:last-child) {
                margin-bottom: 5px;
            }

            a {
                font-weight: 600;
            }
        }

        .col-contact p {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 10px;

            strong {
                color: $heading-color;
            }

            span {
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

    a,
    p {
        color: $body-color;
        margin-bottom: 0;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        line-height: 1.6;
        font-variant-numeric: proportional-nums;
        font-family: $heading-font;
    }

    .copyright {
        font-size: 14px;
        letter-spacing: 2.67px;
        border-top: 1px solid $border-color;

        p {
            text-transform: uppercase;
        }

        .copyright-text {
            color: $body-color;
            margin-top: 5px;
            font-weight: 500;

            a {
                margin-left: 5px;
                color: #a56433;
            }
        }
    }
}
