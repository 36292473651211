@import "../../assets/sass/helper";

/**
  Header Normal
 */
.hn {
  position: relative;
  padding: 150px 0;
  overflow: hidden;
}

/**
  Header Normal
 */
.hn2 {
  position: relative;
  padding: 150px 0 120px 0;
  overflow: hidden;
}


/**
Scroll Down
 */
.sd {
  color: $heading-color;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  height: 15px;
  margin-top: 30px;
}

/**
bg-circle-dotted
 */
.bcd {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30vw;
  background-image: url('./circle-dotted.png');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

/**
  Header Title
 */
.ht {
  max-width: 950px;
  line-height: 1.2;
}

/**
Header Description in Header Normal Tow
 */
.hd {
  position: relative;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: 5px;
  text-transform: uppercase;
  &:before{
    content: "";
    height: 1px;
    background: $heading-color;
    position: absolute;
    transform: translateX(-100%);
    left: -50px;
    width: 150px;
    top: 50%;
  }
}