@import '../../../assets/sass/helper';

.btn-default {
    display: inline-flex;
    align-items: center;
    padding: 10px 25px;
    border: 1px solid $border-color;
    border-radius: 30px;
    color: $heading-color;
    transition: all 0.5s $easeInOutSine;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $heading-color;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        transition: all 0.5s $easeInOutSine;

        svg {
            width: 6px;

            path {
                fill: $bg-color;
                transition: all 0.5s $easeInOutSine;
            }
        }
    }

    .text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 4px;
    }

    &:hover {
        color: $heading-color;
        background-color: $border-color;

        .icon {
            background-color: $bg-color;
            svg {
                path {
                    fill: $heading-color;
                }
            }
        }
    }
}

.btn-alt {
    color: #a56433;

    .icon {
        svg {
            width: 16px;

            path {
                fill: #a56433;
                transition: all 0.5s $easeInOutSine;
            }
        }
    }

    &:hover {
        .icon {
            svg {
                path {
                    fill: #a56433;
                }
            }
        }
    }
}
