.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    position: relative;
    bottom: auto;
    margin-top: 16px;
    --swiper-pagination-color: $heading-color;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 1000px;
    margin-right: 5px;
    cursor: pointer;
    background: $heading-color;
    transition: transform 0.3s ease-in-out;
    &.swiper-pagination-bullet-active {
        width: 12px;
    }
}

.swiper-button-prev,
.swiper-button-next {
    top: 100px !important;
    color: var(--border-color) !important;
}

@include media_768(max) {
    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }
}
